<template>
  <div>
    <div class="sidebar">
      <div>
        <svg-icon class="icon" icon-class="sidebar-tel" />
        <span>电话咨询</span>
        <div class="tooltip">
          <div class="label">咨询热线</div>
          <div>400-030-2013</div>
        </div>
      </div>
      <div @click="contactFormShow=true">
        <svg-icon class="icon" icon-class="sidebar-submit" />
        <span>在线提交</span>
      </div>
      <div>
        <svg-icon class="icon" icon-class="sidebar-email" />
        <span>客服邮箱</span>
        <div class="tooltip">
          <div class="label">客服邮箱</div>
          <div>service@zxca.cn</div>
        </div>
      </div>
      <div @click="goTop">
        <svg-icon class="icon" icon-class="sidebar-gotop" />
        <span>返回顶部</span>
      </div>
    </div>
    <transition name="el-fade-in">
      <div v-show="contactFormShow" class="contact-form">
        <div class="box">
          <div class="left">
            <div class="text">
              快速便捷足不出户在线申请证书<br>简单3步完成申请
            </div>
            <div class="steps">
              <div>
                <img src="@/assets/icons/home/contact-form-icon1.png" class="icon" alt="认证icon" srcset="">
                实名认证
                <span>第一步</span>
              </div>
              <div class="arrows">
                <svg-icon class="arrow" icon-class="pulldown" />
                <svg-icon class="arrow" icon-class="pulldown" />
              </div>
              <div>
                <img src="@/assets/icons/home/contact-form-icon2.png" class="icon" alt="申请icon" srcset="">
                证书申请
                <span>第二步</span>
              </div>
              <div class="arrows">
                <svg-icon class="arrow" icon-class="pulldown" />
                <svg-icon class="arrow" icon-class="pulldown" />
              </div>
              <div>
                <img src="@/assets/icons/home/contact-form-icon3.png" class="icon" alt="证书icon" srcset="">
                下发证书
                <span>第三步</span>
              </div>
            </div>
          </div>
          <div class="form">
            <div class="tittle">
              <span>项目咨询</span>
              <div class="line"></div>
              <div class="hint">请填写以下资料，稍后客服会与您联系</div>
            </div>
            <el-form ref="contactForm" :show-message="false" status-icon hide-required-asterisk :model="contactForm" :rules="contactFormRules" label-position="left" label-width="100px">
              <el-form-item label="公司名称" prop="company">
                <el-input v-model="contactForm.company" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="姓名" prop="personName">
                <el-input v-model="contactForm.personName" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="phone">
                <el-input v-model="contactForm.phone" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label-width="0px">
                <el-input type="textarea" maxlength="150" :rows="2" placeholder="您的需求" v-model="contactForm.requirement"></el-input>
              </el-form-item>
            </el-form>
            <el-button type="primary" @click="projectconsult">在线咨询</el-button>
          </div>
          <div class="close" @click="contactFormShow=false">
            <svg-icon class="icon" icon-class="close-icon" />
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      contactFormShow: false,
      contactForm: {
        company: '',
        personName: '',
        phone: '',
        requirement: ''
      },
      contactFormRules: {
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        personName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.$bus.$on('cgContactFormShow', val => {
      this.contactFormShow = val
    })
  },
  computed: {},
  methods: {
    goTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
    projectconsult () {
      this.$refs.contactForm.validate(valid => {
        if (valid) {
          this.$tencentCaptcha(val => {
            this.$api
              .projectconsult({ ...val, ...this.contactForm })
              .then(() => {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                this.contactFormShow = false
              })
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar {
  position: fixed;
  // left: 200px;
  right: 16px;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%);
  & > div {
    width: 88px;
    height: 64px;
    background: #f9fbff;
    box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
    border-radius: 4px;
    font-size: 12px;
    color: #303b50;
    line-height: 16px;
    margin-bottom: 16px;
    text-align: center;
    position: relative;
    padding-top: 11px;
    cursor: pointer;
    &:hover {
      background: $primaryColor;
      color: #fff;
      .icon {
        color: #fff;
      }
      .tooltip {
        display: block;
      }
    }
    .icon {
      display: block;
      font-size: 18px;
      margin: 0 auto;
      margin-bottom: 8px;
      color: #7b899d;
    }
    .tooltip {
      display: none;
      position: absolute;
      white-space: nowrap;
      right: 112px;
      background: #f9fbff;
      box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
      border: 1px solid #ebf1fc;
      border-radius: 4px;
      top: 50%;
      transform: translateY(-50%);
      padding: 12px;
      color: $primaryColor;
      font-size: 18px;
      line-height: 24px;
      .label {
        font-size: 12px;
        color: #303b50;
        line-height: 16px;
        margin-bottom: 8px;
      }
      &::after {
        content: '';
        position: absolute;
        z-index: 9;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        width: 0px;
        right: -12px;
        border-left: 12px solid #f9fbff;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.contact-form {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  .box {
    width: 1160px;
    height: 780px;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > div {
      width: 50%;
    }
    .left {
      background-image: url('~@/assets/image/home/contact-form-bg.png');
      background-size: 100% 100%;
      padding-top: 116px;
      .text {
        padding: 0 64px;
        line-height: 86px;
        font-size: 28px;
        color: #ffffff;
        letter-spacing: 1px;
        margin-bottom: 98px;
      }
      .steps {
        display: flex;
        padding: 68px;
        justify-content: space-between;

        & > div {
          font-size: 18px;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
          .icon {
            width: 66px;
            height: 66px;
            margin: 0 auto;
            margin-bottom: 21px;
            display: block;
          }
          span {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            line-height: 19px;
            display: block;
            margin-top: 8px;
          }
        }
        .arrows {
          padding: 26px 0;
          .arrow {
            font-size: 14px;
            transform: rotate(-90deg);
            color: #ffffff;
            &:first-child {
              color: #5c91f5;
            }
          }
        }
      }
    }
    .form {
      background-color: #fff;
      padding: 52px 40px;
      text-align: center;
      .tittle {
        font-size: 24px;
        color: #303b50;
        line-height: 31px;
        text-align: center;
        margin-bottom: 28px;
        .line {
          width: 68px;
          height: 4px;
          background: #0059ff;
          margin: 21px auto 28px auto;
        }
        .hint {
          font-size: 16px;
          color: #aeb5be;
          line-height: 21px;
        }
      }
      ::v-deep.el-form {
        height: 490px;

        .el-form-item {
          border-radius: 2px;
          border: 1px solid #d5dbe2;
          margin-bottom: 32px;
          &.is-error {
            border: 1px solid #f56c6c;
          }
          .el-form-item__label {
            line-height: 60px;
            font-size: 16px;
            padding-left: 16px;
            padding-right: 0;
            &::after {
              content: '*';
              color: #f56c6c;
              margin-left: 4px;
            }
          }
          .el-textarea__inner {
            height: 162px;
            font-size: 16px;
            border: none !important;
            padding: 15px;
            color: #6c737b;
            line-height: 21px;
          }
          .el-input {
            border: none;
            .el-input__inner {
              padding-left: 50px;
              padding-right: 32px;
              font-size: 16px;
              height: 60px;
              border: none !important;
              color: #6c737b;
              line-height: 21px;
            }
          }
        }
      }
      .el-button {
        width: 198px;
        margin: 0 auto;
        font-size: 16px;
        padding: 11px 0;
        border-radius: 2px;
      }
    }
    .close {
      width: auto;
      position: absolute;
      top: 10px;
      right: 12px;
      font-size: 24px;
      cursor: pointer;
      color: #aeb5be;
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
</style>
