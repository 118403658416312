<template>
  <footer>
    <div class="main-box">
      <div class="top">
        <div class="contact">
          <div>
            <svg-icon class="icon" icon-class="footer-tel" />400-030-2013
            <div class="hint">工作日：9:00-18:00</div>
          </div>
          <div>
            <svg-icon class="icon" icon-class="footer-email" />service@zxca.cn
          </div>
          <el-button @click="$bus.$emit('cgContactFormShow', true)" type="primary">在线咨询</el-button>
        </div>
        <div class="link">
          <div class="tt">产品</div>
          <div @click="$router.push({name:'product-eSeal'})">电子签章</div>
          <div @click="$router.push({name:'product-sealBox'})">智能印章一体机</div>
          <div @click="$router.push({name:'product-orgCert'})">机构证书</div>
          <div @click="$router.push({name:'product-personCert'})">个人证书</div>
        </div>
        <div class="link">
          <div class="tt">服务支持</div>
          <div @click="verify('certApply')">证书申请</div>
          <div @click="verify('certRenewal')">证书续期</div>
          <div @click="verify('certUnlock')">介质解锁</div>
          <div @click="$router.push({name:'helpCenter'})">帮助中心</div>
        </div>
        <div class="link">
          <div class="tt">常用链接</div>
          <!-- <div>
            <a
              href="https://yhca-pro-public-download.oss-cn-hangzhou.aliyuncs.com/document/CPS/%E5%B1%B1%E4%B8%9C%E8%B1%B8%E4%BF%A1%E8%AE%A4%E8%AF%81%E6%9C%8D%E5%8A%A1%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%E7%94%B5%E5%AD%90%E8%AE%A4%E8%AF%81%E4%B8%9A%E5%8A%A1%E8%A7%84%E5%88%99CPS-V2.3.docx">
              电子认证服务规则</a>
          </div> -->
          <div><a target="_blank" href="./download/protocol.pdf" rel="nofollow">依赖方协议</a></div>
          <div><a target="_blank" href="https://ythzxfw.miit.gov.cn/resultQuery" rel="nofollow">电子认证服务许可机构单位名录</a></div>
          <div><a target="_blank" href="https://sca.gov.cn/app-zxfw/xzspsx/dzzwdzrzfuwujigouml.jsp?channel_code=c100144" rel="nofollow">电子政务电子认证服务机构名录</a></div>
        </div>
        <div class="link">
          <div class="tt">常用查询</div>
          <router-link target="_blank" :to="{name:'certQuery'}">证书查询</router-link>
          <router-link target="_blank" :to="{name:'ocspQuery'}">ocsp在线查询</router-link>
          <div><a href="ldap://124.133.10.26:389">数字证书目录服务查询</a></div>
        </div>
        <div class="wchart">
          <svg-icon class="icon" icon-class="footer-wx" />
          <div class="qrcode"> <img src="@/assets/image/home/WeChatCode.png" alt="豸信二维码" /></div>
          <div class="hint">关注公众号</div>
        </div>
      </div>
      <div class="bottom">
        <span>Copyright © 2019 山东豸信认证服务有限公司版权所有.</span>
        <span><a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">鲁ICP备15043318号</a> &nbsp;&nbsp; <a target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001700" rel="nofollow">鲁公网安备 37011202001700号</a> </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    verify (name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          let routeData = this.$router.resolve({ name })
          window.open(routeData.href, '_blank')
        } else {
          this.$confirm('当前账号未完成管理员实名认证，实名之后才可办理业务。', '提示', {
            confirmButtonText: '实名认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/personHome' })
          }).catch(() => {

          });
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background-color: #262737;
  .main-box {
    padding-top: 66px;
    position: relative;
    height: 440px;
    .top {
      display: flex;
      justify-content: space-between;
      .contact {
        font-size: 24px;
        color: #ffffff;
        line-height: 36px;
        margin-right: 80px;
        font-weight: 700;
        & > div {
          margin-bottom: 40px;
        }
        .hint {
          font-size: 16px;
          color: #9eaec3;
          line-height: 21px;
          margin-top: 14px;
          padding-left: 51px;
          font-weight: normal;
        }
        .icon {
          font-size: 36px;
          margin-right: 15px;
        }
        .el-button {
          width: 100%;
          height: 46px;
          padding: 15px 0;
          font-size: 16px;
        }
      }
      .link {
        margin-right: 80px;
        .tt {
          line-height: 36px;
          margin-bottom: 32px;
          font-size: 16px;
          color: #ffffff;
          font-weight: 700;
        }
        & > div,
        a {
          display: block;
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 24px;
          cursor: pointer;
          color: #9eaec3;
          &:hover {
            color: #fff;
          }
        }
      }
      .wchart {
        .icon {
          font-size: 32px;
          margin-bottom: 7px;
        }
        .qrcode {
          width: 118px;
          height: 118px;
          background: #ffffff;
          box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
          position: relative;
          border-radius: 2px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 2px;
          }
          &::before {
            content: '';
            position: absolute;
            border-bottom: 5px solid #fff;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            top: -5px;
            left: 10px;
          }
        }
        .hint {
          margin-top: 5px;
          font-size: 14px;
          color: #727887;
          line-height: 19px;
          letter-spacing: 1px;
          text-align: center;
        }
      }
    }
    .bottom {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      line-height: 46px;
      border-top: 1px #393a49 solid;
      font-size: 14px;
      color: #727887;
      letter-spacing: 1px;
      justify-content: space-between;
      a {
        color: #727887;
      }
    }
  }
}
</style>
