<template>
  <header :class="{ blur: menuBlur, dark: ['aboutUs'].includes(routeName) }">
    <div :class="['main-box', { homeNotice: routeName === 'home' }]">
      <div class="logo" @click="goRouter('home')">
        <svg-icon icon-class="zxlogo" />
      </div>

      <div class="menu">
        <div
          v-for="(item, index) in menus"
          :key="index"
          @click="item.children || item.types ? '' : goRouter(item.name)"
        >
          <span
            >{{ item.label }}
            <svg-icon
              v-if="item.children || item.types"
              class="icon"
              icon-class="pulldown"
            />
          </span>
          <div class="submenu" v-if="item.children">
            <div
              @click="goRouter(child.name)"
              v-for="(child, i) in item.children"
              :key="i"
              class="item"
            >
              {{ child.label }}
            </div>
          </div>
          <div class="submenu flex" v-if="item.types">
            <div v-for="(type, i) in item.types" :key="i">
              <div class="label">{{ type.label }}</div>
              <div class="line"></div>
              <div
                @click="goRouter(child.name)"
                v-for="(child, i) in type.items"
                :key="i"
                class="item"
              >
                {{ child.label }}
              </div>
            </div>
          </div>
          <div class="submenuBg" v-if="item.children || item.types"></div>
        </div>
      </div>
      <div></div>
      <div class="right">
        <el-button
          v-if="$store.state.token"
          class="button"
          @click="$router.push({ name: 'personHome' })"
          type="primary"
          >个人中心</el-button
        >
        <el-button
          class="button"
          v-else
          @click="$bus.$emit('cgLoginShow', true)"
          type="primary"
          >登录</el-button
        >
        <div
          class="notice"
          v-if="
            $store.state.businessListData &&
              $store.state.businessListData.total > 0 &&
              $store.state.token
          "
        >
          <div class="noticeTitle">
            <span class="el-icon-message-solid"></span> 【通知】您有{{
              $store.state.businessListData.total
            }}个证书业务单正在进行中
          </div>
          <p v-if="$store.state.businessListData.records[0].status == 1">
            您提交的【{{ $store.state.businessListData.records[0].name }}】{{
              $store.state.businessListData.records[0].type
                | transactionTypeFilter
            }}业务还未上传资料，请尽快上传办理业务
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 3">
            您提交的【{{ $store.state.businessListData.records[0].name }}】{{
              $store.state.businessListData.records[0].type
                | transactionTypeFilter
            }}业务审核不通过，不通过原因：{{
              $store.state.businessListData.records[0].auditComment
            }}
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 4">
            您提交的【{{ $store.state.businessListData.records[0].name }}】{{
              $store.state.businessListData.records[0].type
                | transactionTypeFilter
            }}业务已经通过审核，待支付金额{{
              $store.state.businessListData.records[0].discountTotal
            }}元
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 6">
            您提交的【{{ $store.state.businessListData.records[0].name }}】{{
              $store.state.businessListData.records[0].type
                | transactionTypeFilter
            }}业务正在等待续期，请尽快进行续期。
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 14">
            您提交的【{{ $store.state.businessListData.records[0].name }}】{{
              $store.state.businessListData.records[0].type
                | transactionTypeFilter
            }}业务正在等待变更，请尽快进行变更。
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 7">
            您提交的【{{ $store.state.businessListData.records[0].name }}】{{
              $store.state.businessListData.records[0].type
                | transactionTypeFilter
            }}业务正在等待解锁，请尽快进行解锁。
          </p>

          <div class="noticeBtn" @click="getRouter">查看详情</div>
          <div class="el-icon-close close" @click="closeNotice"></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { transactionType } from '@/utils/typeMatch.js'
export default {
  name: 'Header',
  data() {
    return {
      routeName: 'home',
      menuBlur: false,
      popoverSow: true,
      transaction_list: null,
      menus: [
        {
          name: 'home',
          label: '首页'
        },
        {
          label: '产品中心',
          types: [
            {
              label: '数字证书',
              items: [
                {
                  name: 'product-orgCert',
                  label: '机构证书'
                },
                {
                  name: 'product-personCert',
                  label: '个人证书'
                }
              ]
            },
            {
              label: '电子签章',
              items: [
                {
                  name: 'product-sealBox',
                  label: '智能印章一体机'
                },
                {
                  name: 'product-eSeal',
                  label: '电子签章系统'
                },
                {
                  name: 'product-electronicSignature',
                  label: '电子签章客户端'
                }
              ]
            },
            {
              label: '数据安全与密码服务',
              items: [
                {
                  name: 'product-verifySign',
                  label: '签名验签'
                },
                {
                  name: 'product-timestamp',
                  label: '可信时间戳'
                }
              ]
            },
            {
              label: '移动端数字密码服务',
              items: [
                {
                  name: 'product-mobileShield',
                  label: '手机盾'
                }
              ]
            }
          ]
        },
        {
          name: 'serviceHall',
          label: '服务大厅'
        },
        {
          name: 'businessCooperation',
          label: '业务合作'
        },
        {
          name: 'openPlatform',
          label: '开放平台'
        },
        {
          label: '帮助与下载',
          children: [
            {
              name: 'helpCenter',
              label: '帮助中心'
            },
            {
              name: 'downloadCenter',
              label: '下载专区'
            }
          ]
        },
        {
          label: '关于豸信',
          children: [
            {
              name: 'aboutUs',
              label: '关于我们'
            },
            {
              name: 'pressCenter',
              label: '新闻中心'
            }
          ]
        }
      ]
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    // this.$cookie.get('token')
  },
  filters: {
    transactionTypeFilter: transactionType
  },
  methods: {
    closeNotice() {
      this.$store.commit('SET_BUSINESSLISTDATA', {})
    },
    getRouter() {
      this.closeNotice()
      this.$router.push({ path: '/serviceCenter', query: { type: 1 } })
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop == 0) {
        this.menuBlur = false
      } else {
        this.menuBlur = true
      }
    },
    goRouter(name) {
      if (name === 'openPlatform') {
        switch (process.env.VUE_APP_ENV) {
          case 'serve':
            window.open('https://dev-developer.zxca.cn/api-home', '_blank')
            break
          case 'dev':
            window.open('https://dev-developer.zxca.cn/api-home', '_blank')
            break
          case 'test':
            window.open('https://sit-developer.zxca.cn/api-home', '_blank')
            break
          case 'prod':
            window.open('https://developer.zxca.cn/api-home', '_blank')
            break
          case 'pre':
            window.open('https://developer.zxca.cn/api-home', '_blank')
            break
          case 'dist':
            window.open('https://developer.zxca.cn/api-home', '_blank')
            break
          default:
            break
        }
        return
      }
      this.$router.push({ name: name })
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.routeName = this.$route.name
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: background 0.4s;
  // border-bottom: 1px #e1e2e6 solid;
  &.dark {
    // border-bottom: 1px #3541da solid;
    .main-box {
      .menu,
      .logo {
        color: #fff;
      }
    }
  }
  // 首页通告
  // .homeNotice {
  //   margin-top: 42px;
  // }
  &.blur {
    .homeNotice {
      margin-top: 0;
    }
  }
  &.blur,
  &:hover {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
    backdrop-filter: blur(10px);
    border: none;
    .main-box {
      .menu {
        color: #303b50;
      }
      .logo {
        color: $primaryColor;
      }
    }
  }
  .active {
    color: $primaryColor;
  }
  .main-box {
    max-width: 100%;
    width: 100%;
    padding: 0 48px;
    display: flex;
    align-items: flex-start;
    .logo {
      margin-right: 48px;
      color: $primaryColor;
      font-size: 44px;
      line-height: 60px;
      cursor: pointer;
    }
    .menu {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      color: #303b50;
      line-height: 60px;
      width: 100%;
      & > div {
        margin-right: 48px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .icon {
          font-size: 6px;
          margin-left: 6px;
          transition: all 0.4s;
        }
        .submenuBg {
          transition: all 0.4s;
          height: 0;
        }
        &:nth-child(2):hover {
          .submenu,
          .submenuBg {
            height: 200px;
          }
        }

        &:hover {
          color: $primaryColor;
          overflow: visible;
          .submenu,
          .submenuBg {
            height: 102px;
          }
          .submenu {
            visibility: visible;
            margin-left: 0;
            opacity: 1;
          }
          .icon {
            transform: rotate(180deg);
          }
        }

        .submenu {
          transition: all 0.4s;
          opacity: 0;
          color: #303b50;
          line-height: 14px;
          position: absolute;
          left: 12px;
          top: 60px;
          height: 0;
          overflow: hidden;
          box-sizing: border-box;
          white-space: nowrap;
          visibility: hidden;
          margin-left: 20px;
          .item {
            line-height: 43px;
            &:hover {
              color: $primaryColor;
            }
          }
          &.flex {
            flex-shrink: 0;
            white-space: nowrap;
            display: flex;
            & > div {
              flex-shrink: 0;
              display: inline;
              padding-right: 80px;
            }
            .label {
              font-size: 14px;
              color: #aeb5be;
              line-height: 50px;
              text-shadow: 0px 9px 46px rgba(0, 0, 0, 0.16);
            }
            .line {
              width: 106px;
              height: 1px;
              background: #dee2e8;
              box-shadow: 0px 9px 46px 0px rgba(0, 0, 0, 0.16);
              margin-bottom: 12px;
            }
          }
        }
      }
      .activ {
        color: $primaryColor;
      }
    }
  }
  .right {
    margin-top: 10px;
    position: relative;
    .notice {
      position: absolute;
      right: 0;
      top: 50px;
      width: 378px;
      height: 148px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(31, 37, 81, 0.11);
      border-radius: 10px;
      padding: 16px;
    }
    .noticeTitle {
      font-size: 16px;
      color: #303b50;
      line-height: 21px;
      padding-bottom: 12px;
    }
    p {
      padding: 0 20px;
      font-size: 14px;
      color: #8d96a3;
      line-height: 24px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical; //盒子中内容竖直排
      height: 52px;
    }
    .noticeBtn {
      font-size: 14px;
      color: #3a6afe;
      line-height: 19px;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 16px;
      cursor: pointer;
    }
    .close {
      position: absolute;
      top: 12px;
      right: 14px;
      cursor: pointer;
    }
  }
}
</style>
